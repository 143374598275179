import React, { useEffect, useState } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { UserNavigation } from "../../../components/UserNavigation";

const stripePromise = loadStripe('pk_test_51JMXfpCXksc90JSP0KtdPx2ygahHizMwrIo24RBX0wvNAXbqkeBckPCZZt8BSwLVg73Fes3n34HDj6tawSZnEN3a00YMrJSMLU');

function CheckoutForm({ clientSecret, paymentId }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: "https://usell.be/betaling/success",
      },
      redirect: 'if_required'
    });

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    } else if (setupIntent && setupIntent.status === 'succeeded') {
      try {
        const response = await fetch(`https://usell.be/payment/save-payment-method/${paymentId}/`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
        });

        if (!response.ok) throw new Error("Failed to save payment method");

        navigate('/betaling/success');
      } catch (error) {
        setErrorMessage("Betaling succesvol, maar er was een probleem met het opslaan van de betaalmethode.");
        console.error("Error saving payment method:", error);
        setIsProcessing(false);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Button variant="primary" type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? "Bezig..." : "Opslaan en Betalen"}
      </Button>
    </Form>
  );
}

export function CheckoutPage() {
  const { paymentId } = useParams();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch(`https://usell.be/payment/create-setup-intent/${paymentId}/`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
          body: JSON.stringify({ payment_id: paymentId }),
        });

        if (!response.ok) throw new Error("Failed to create setup intent");

        const data = await response.json();
        setClientSecret(data.client_secret);
        setLoading(false);
      } catch (error) {
        setError("Er was een probleem met het voorbereiden van de betaling.");
        console.error("Error fetching client secret:", error);
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, [paymentId]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (loading) {
    return <div>Bezig met laden...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <UserNavigation />
      <div className={"appointments-hero"}>
        <div className={"hero-background appointments-background"}></div>
        <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
          <h1 className={"appointments-title"}>Vervolledig je Betaling</h1>
        </Container>
      </div>
      <Container className="mt-5">
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} paymentId={paymentId} />
        </Elements>
      </Container>
    </>
  );
}