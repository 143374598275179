import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";


export function LoginPage(props) {
    const { user, userMetadata, isAuthenticated } = useContext(AuthContext);


    return (
        isAuthenticated && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
                <p>{user.sub}</p>
                <h3>User Metadata</h3>
                {userMetadata ? (
                    <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
                ) : (
                    "No user metadata defined"
                )}

                <h2>usage</h2>
                <h2>{userMetadata.following}</h2>/
                <h2>{userMetadata.website}</h2>
            </div>


        )
    );
};

