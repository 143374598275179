import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBzsc-MhnD-4UFNNaVi5lynHIvKIyi2HvU",
    authDomain: "usell-ccdde.firebaseapp.com",
    projectId: "usell-ccdde",
    storageBucket: "usell-ccdde.appspot.com",
    messagingSenderId: "868001349654",
    appId: "1:868001349654:web:be1af55adbcd7c543a3537"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage, ref, uploadBytes, getDownloadURL };