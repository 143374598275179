import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export function RegisterPage(props) {
    const { user, userMetadata, isAuthenticated, redirectToCompleteProfile } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && redirectToCompleteProfile) {
            navigate('/vervolledig-profiel');
        }
    }, [isAuthenticated, redirectToCompleteProfile, navigate]);

    return (
        isAuthenticated && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                <div style={{ marginBottom: '20px' }}>
                    <img src={user.picture} alt={user.name} style={{ borderRadius: '50%', width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '10px', color: '#333' }}>{user.name}</h2>
                <p style={{ fontSize: '1rem', marginBottom: '10px', color: '#666' }}>{user.email}</p>
                <h3 style={{ fontSize: '1.2rem', marginBottom: '10px', color: '#888' }}>User Metadata</h3>
                {userMetadata && Object.keys(userMetadata).length > 0 ? (
                    <pre style={{ fontSize: '0.9rem', backgroundColor: '#f1f1f1', padding: '10px', borderRadius: '5px' }}>
                        {JSON.stringify(userMetadata, null, 2)}
                    </pre>
                ) : (
                    <p style={{ fontSize: '1rem', color: '#888' }}>No user metadata defined</p>
                )}
            </div>
        )
    );
}