export function NotFoundPage(props) {


    return (<>
        <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="text-center text-md-left">
                            <h2 className="display-4 mt-5">Je hebt de verkeerde afslag genomen!</h2>
                            <p className="lead">De pagina die je zoekt bestaat niet.</p>
                            <a href="/" className="btn btn-outline-dark btn-sm mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" height="30px" width="30px"
                                     viewBox="0 0 1024 1024">
                                    <path fill="currentColor"
                                          d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path>
                                </svg>
                                Terug naar home
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src="/gifs/404.gif" alt="GIF of 404 with passing car" className="img-fluid"/>
                    </div>
                </div>
            </div>


        </section>


    </>);
}