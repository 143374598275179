import FAQ from "../components/FAQ";
import {useState} from "react";

export function FaqPage(props) {
    const [faqs, setFaqs] = useState([
        {
            "question": "Wat is Usell?",
            "answer": "Usell is een online veilingsplatform waar u uw tweedehandse wagen kan verkopen. We bieden een snelle en gemakkelijke manier om uw auto te verkopen voor een eerlijke prijs.",
            "open": false
        },
        {
            "question": "Hoe werkt de autoveiling op Usell?",
            "answer": "Om uw auto te verkopen, meld u zich aan en vult u de nodige informatie over uw auto in. Vervolgens wordt uw auto geplaatst in een online veiling waar geïnteresseerde kopers biedingen kunnen plaatsen.",
            "open": false
        },
        {
            "question": "Wat zijn de kosten voor het gebruik van Usell?",
            "answer": "Er zijn geen kosten verbonden aan het gebruik van Usell voor het verkopen van voertuigen. Alleen aan professionals die voertuigen aankopen, zullen kosten in rekening gebracht worden.",
            "open": false
        },
        {
            "question": "Wat gebeurt er als mijn auto niet wordt verkocht via Usell?",
            "answer": "Als uw auto niet wordt verkocht via Usell, kunt u ervoor kiezen om de veiling opnieuw te starten of om uw auto van het platform te halen.",
            "open": false
        },
        {
            "question": "Hoe kan ik de waarde van mijn auto bepalen voordat ik deze verkoop via Usell?",
            "answer": "Bij het online plaatsen van uw voertuig zal u gevraagd worden naar een gewenst verkoopbedrag. Het hoogste bedrag dat u zal krijgen voor uw wagen zal een marktconform bedrag zijn op het moment dat de veiling online staat.",
            "open": false
        },
        {
            "question": "Kan ik contact opnemen met de klantenservice als ik vragen of problemen heb?",
            "answer": "Ja, als u vragen of problemen heeft, kunt u contact opnemen met onze klantenservice via e-mail of telefoon. Ons team staat klaar om u te helpen en uw vragen te beantwoorden.",
            "open": false
        },
        {
            "question": "Kan eender welke wagen verkocht worden op Usell.be?",
            "answer": "Ja, elke wagen die rechtstreeks uit de hand van de rechtmatige eigenaar kan verkocht worden, is aan te bieden op Usell.be.",
            "open": false
        },
        {
            "question": "Wat als ik niet alle schade en informatie deel i.v.m. mijn wagen en dit tot discussie leidt en de verkoop niet doorgaat?",
            "answer": "Indien de professional zich terugtrekt van de aankoop van uw wagen, zal deze bewijsvoering moeten voorleggen. Indien blijkt dat u als klant nalatig informatie heeft achtergehouden die de waarde van het voertuig negatief beïnvloedt, kan dit resulteren in het annuleren van de verkoop.",
            "open": false
        },
        {
            "question": "Kan ik meerdere wagens plaatsen op Usell?",
            "answer": "Zolang u de rechtmatige eigenaar bent, kan u zeker meerdere wagens te koop aanbieden op Usell.be",
            "open": false
        },
        {
            "question": "Kan ik mijn bedrijfswagens aanbieden?",
            "answer": "Usell is erop voorzien dat ook BTW voertuigen aangeboden kunnen worden. Geef uw BTW nummer aan bij de registratie en gebruik de naam van de rechtspersoon/bedrijf als gegevens.",
            "open": false
        },
        {
            "question": "Kan ik een bod weigeren?",
            "answer": "Ja, bij Usell geloven wij dat de controle volledig aan de eigenaar toebehoort. Dit wil zeggen dat enkel jij zelf de keuze hebt om een bod te accepteren.",
            "open": false
        },
        {
            "question": "Is mijn verkoop verzekerd?",
            "answer": "Als je akkoord gaat met een bod, dient de plaatselijke controle enkel uitgevoerd te worden door de professional. Zodra deze aangeeft dat de wagen overeenkomt zoals geadverteerd door jouzelf, zal de verkoop doorgaan met goedkeuring van beide partijen.",
            "open": false
        },
        {
            "question": "Moet ik mijn auto keuren?",
            "answer": "Neen, volgens de wetgeving ben je verplicht om de keuring voor verkoop uit te voeren als je een wagen verkoopt aan een ander natuurlijk persoon. Aangezien enkel professionals jouw wagen kunnen kopen via Usell dien je enkele de laatste keuring aan te tonen.",
            "open": false
        },
        {
            "question": "Is het herroepingsrecht van toepassing?",
            "answer": "Nee, als consument in dit verhaal verkoop je de wagen aan een professional, deze zijn verplicht om hun onderzoek te verrichten ter plaatse. Als ze akkoord gaan met de verkoop van het voertuig, kunnen zij zich niet beroepen op het herroepingsrecht.",
            "open": false
        },
        {
            "question": "Ik kan mij niet aanmelden?",
            "answer": "Indien je je niet kan aanmelden, probeer dan nog eens opnieuw en controleer je mail voor enige informatie. Indien je nog steeds problemen ondervindt, stuur dan een mail naar info@usell.be.",
            "open": false
        },
        {
            "question": "Zijn de betalingen veilig en hoe worden deze uitgevoerd?",
            "answer": "Onze partner Stripe zorgt voor de uitvoering van veilige betalingen. Na akkoord van de verkoop van je wagen zal Usell met Stripe de nodige stappen uitvoeren om je fondsen correct en snel te laten storten op je rekening.",
            "open": false
        },
        {
            "question": "Wat als de transactie niet meteen wordt uitgevoerd?",
            "answer": "Als u akkoord gaat met de verkoop ter plaatse via u privé-dashboard, dan dient de professional ook dezelfde actie te ondernemen om de transactie direct te verwerken. Bij eventuele problemen kan contact worden opgenomen zodat de betaling correct wordt afgehandeld.",
            "open": false
        },
        {
            "question": "Zijn er nog prijsonderhandelingen nadat ik het bod heb geaccepteerd?",
            "answer": "Bij de controle van het voertuig is het voertuig identiek zoals omschreven op het platform. Zowel jijzelf als de professional bevestigen de transactie op het platform. Hierdoor is er geen ruimte voor verdere prijsonderhandelingen bij de controle van het voertuig.",
            "open": false
        },
        {
            "question": "Zijn mijn persoonlijke gegevens veilig bij Usell?",
            "answer": "Bij Usell verbinden wij ons om uw gegevens veilig te houden volgens de wetgeving en met passende technologische beveiligingsmaatregelen.",
            "open": false
        },
        {
            "question": "Moet ik mijn thuisadres opgeven als ophaaladres?",
            "answer": "Neen, dit is niet verplicht. Het platform laat toe om een ander adres in te voeren als ophaaladres om uw comfort en veiligheid te waarborgen.",
            "open": false
        },
        {
            "question": "Zijn de professionals te vertrouwen?",
            "answer": "Usell garandeert dat de professionals zorgvuldig gescreend worden en dat feedback van klanten een rol speelt in het vertrouwen dat aan professionals wordt gehecht.",
            "open": false
        }
    ]);


    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };


    return (
        <div className="container ">
            <h3 className={""}>veel gestelde vragen</h3>
            <div className={"row"}>
                <div className="faqs row pt-3">
                    {faqs.map((faq, index) => (
                        <div className={"col-6 my-3"}>
                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}