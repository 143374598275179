import React from 'react';
import { Container } from 'react-bootstrap';
import { UserNavigation } from '../../../components/UserNavigation';

export function DeniedOnboardingPage() {
    return (
        <>
            <UserNavigation />
            <div className="content-padding-top-lg content-padding-bottom-lg">
                <Container>
                    <h1>Onboarding Denied</h1>
                    <p>Unfortunately, the onboarding process was not successful. Please try again or contact support for assistance.</p>
                </Container>
            </div>
        </>
    );
}