import "normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './CookiePopup.css';
import './i18n';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IndexPage } from "./pages/IndexPage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { ContactPage } from "./pages/ContactPage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { AdminPanelPage } from "./pages/AdminPages/AdminPanelPage";
import { AdminManageUserPage } from "./pages/AdminPages/AdminManageUserPage";
import { AdminDisabledAuctionsPage } from "./pages/AdminPages/AdminDisabledAuctionsPage";
import { AuctionsPage } from "./pages/AuctionsPage";
import { ProfilePage } from "./pages/user-pages/ProfilePage";
import { AgendaPage } from "./pages/AgendaPage";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import { AuctionDetailsPage } from "./pages/AuctionDetailsPage";
import { UserAuctionsPage } from "./pages/user-pages/UserAuctionsPage";
import { FollowingAuctionsPage } from "./pages/user-pages/FollowingAuctionsPage";
import { EndingAuctionsPage } from "./pages/EndingAuctionsPage";
import { NewAuctionsPage } from "./pages/NewAuctionsPage";
import { WinningAuctionsPage } from "./pages/user-pages/WinningAuctionsPage";
import { WonAuctionsPage } from "./pages/user-pages/WonAuctionsPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { FaqPage } from "./pages/FaqPage";
import AdminAuctionsPage from "./pages/AdminPages/ManageAuctions";
import { CreateAuctionPage } from "./pages/user-pages/CreateAuctionPage";
import { AppointmentsPage } from "./pages/user-pages/AppointmentPage";
import { NotificationProvider } from './contexts/NotificationContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from "./contexts/AuthContext";
import { CompleteProfilePage } from "./pages/CompleteProfilePage";
import ProtectedRoute from './components/ProtectedRoute';
import BuyerRoute from './components/BuyerRoute';
import SellerRoute from './components/SellerRoute';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import { PaymentPage } from './pages/user-pages/payment/PaymentPage';
import { CheckoutPage } from './pages/user-pages/payment/CheckoutPage';
import { PaymentSuccessPage } from './pages/user-pages/payment/PaymentSuccessPage';
import { PaymentDeclinedPage } from "./pages/user-pages/payment/PaymentDeclinedPage";
import { UserDashboardPage } from './pages/user-pages/Dashboard';
import { MyBidsPage } from './pages/user-pages/MyBidsPage';
import { SuccessOnboardingPage } from './pages/user-pages/onboarding/SuccesOnboardingPage';
import { DeniedOnboardingPage } from './pages/user-pages/onboarding/DeniedOnboardingPage';
import { RelistAuctionPage } from './pages/user-pages/RelistAuctionPage';
import { StockDetailPage } from "./pages/stockDetailPage";
import { SellStockPage } from "./pages/sellStockPage";
import { MyStockPage } from "./pages/myStockPage";
import { ForsaleStockPage } from "./pages/forsaleStockPage";
import { MyStockBidsPage } from "./pages/MyStockBidsPage";
import { ArchivedStockPage } from "./pages/archivedStockPage";
import CookiePopup from "./components/CookiePopup";

function App() {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_AUTH0PROVIDER}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.AUTH0_AUDIENCE
            }}
        >
            <AuthProvider>
                <NotificationProvider>
                    <AppContent />
                </NotificationProvider>
            </AuthProvider>
        </Auth0Provider>
    );
}

function AppContent() {

    return (
        <>
            <BrowserRouter>
                <Navigation />
                <CookiePopup />
                <Routes>

                    <Route path={"/"} element={<IndexPage />}></Route>
                    <Route path={"/over-ons"} element={<AboutUsPage />}></Route>
                    <Route path={"/word-lid"} element={<RegisterPage />}></Route>
                    <Route path={"/contacteer-ons"} element={<ContactPage />}></Route>
                    <Route path={"/aanmelden"} element={<LoginPage />}></Route>
                    <Route path={"/vervolledig-profiel"} element={<CompleteProfilePage />}></Route>
                    <Route path={"/faq"} element={<FaqPage />}></Route>
                    <Route path={"*"} element={<NotFoundPage />}></Route>

                    <Route path={"/admin/veilingen"} element={<ProtectedAdminRoute><AdminAuctionsPage /></ProtectedAdminRoute>} />
                    <Route path={"/admin/afgekeurde-wagens"} element={<ProtectedAdminRoute><AdminDisabledAuctionsPage /></ProtectedAdminRoute>} />
                    <Route path={"/admin"} element={<ProtectedAdminRoute><AdminPanelPage /></ProtectedAdminRoute>}></Route>
                    <Route path={"/admin/gebruikers"} element={<ProtectedAdminRoute><AdminManageUserPage /></ProtectedAdminRoute>} />

                    <Route path={"/veiling/:id"} element={<ProtectedRoute><AuctionDetailsPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/profiel"} element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard"} element={<ProtectedRoute><UserDashboardPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/afspraken"} element={<ProtectedRoute><AppointmentsPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/onboarding/voltooid"} element={<ProtectedRoute><SuccessOnboardingPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/onboarding/geweigerd"} element={<ProtectedRoute><DeniedOnboardingPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/agenda"} element={<ProtectedRoute><AgendaPage /></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/agenda/:appointmentId"} element={<ProtectedRoute><AgendaPage /></ProtectedRoute>}></Route>

                    <Route path={"/dashboard/volgend"} element={<ProtectedRoute><BuyerRoute><FollowingAuctionsPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/gewonnen"} element={<ProtectedRoute><BuyerRoute><WonAuctionsPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"/veilingen"} element={<ProtectedRoute><BuyerRoute><AuctionsPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/winnende-veilingen"} element={<ProtectedRoute><BuyerRoute><WinningAuctionsPage /></BuyerRoute></ProtectedRoute>}></Route>
		    <Route path={"/dashboard/stock/biedingen"} element={<ProtectedRoute><BuyerRoute><MyStockBidsPage /></BuyerRoute></ProtectedRoute>}></Route>

                    <Route path={"/stock/:id"} element={<ProtectedRoute><BuyerRoute><StockDetailPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"dashboard/stock/verkoop"} element={<ProtectedRoute><BuyerRoute><SellStockPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"dashboard/stock/mijn-stock"} element={<ProtectedRoute><BuyerRoute><MyStockPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"dashboard/stock/te-koop"} element={<ProtectedRoute><BuyerRoute><ForsaleStockPage /></BuyerRoute></ProtectedRoute>}></Route>
                    <Route path={"dashboard/stock/archief"} element={<ProtectedRoute><BuyerRoute><ArchivedStockPage /></BuyerRoute></ProtectedRoute>}></Route>

                    <Route path={"/dashboard/mijn-veilingen"} element={<ProtectedRoute><SellerRoute><UserAuctionsPage /></SellerRoute></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/maak-veiling"} element={<ProtectedRoute><SellerRoute><CreateAuctionPage /></SellerRoute></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/plaats-veiling-opnieuw"} element={<ProtectedRoute><SellerRoute><RelistAuctionPage /></SellerRoute></ProtectedRoute>}></Route>
                    <Route path={"/dashboard/biedingen"} element={<ProtectedRoute><SellerRoute><MyBidsPage /></SellerRoute></ProtectedRoute>}></Route>

                    <Route path={"/aflopende-veilingen"} element={<ProtectedRoute><EndingAuctionsPage /></ProtectedRoute>}></Route>
                    <Route path={"/nieuwe-veilingen"} element={<ProtectedRoute><NewAuctionsPage /></ProtectedRoute>}></Route>
                    <Route path={"/betaling/success"} element={<ProtectedRoute><PaymentSuccessPage /></ProtectedRoute>}></Route>
                    <Route path={"/betaling"} element={<ProtectedRoute><PaymentPage /></ProtectedRoute>}></Route>
                    <Route path={"/betaling/:paymentId/afrekenen"} element={<ProtectedRoute><CheckoutPage /></ProtectedRoute>}></Route>
                    <Route path={"/betaling/afgewezen"} element={<ProtectedRoute><PaymentDeclinedPage /></ProtectedRoute>}></Route>

                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
