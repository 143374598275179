import Container from "react-bootstrap/Container";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { useSpring, animated } from "@react-spring/web";

export function ContactPage(props) {

    const { t } = useTranslation();

    const bounce1 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-1px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 0,
    });

    const bounce2 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-1px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 200,
    });

    const bounce3 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-1px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 400,
    });

    const bounce4 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-1px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 600,
    });

    return (
        <>
            <section className="contact-section">
                <div className={"contact-hero"}>
                    <div className={"hero-background"}></div>
                    <Container className={"content content-padding-bottom-lg"}>
                        <motion.h1
                            className="responsive-title"
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                        >
                            {t('contact_title')}
                        </motion.h1>
                        <motion.h4
                            initial={{ opacity: 0, x: 50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1, delay: 0.2 }}
                        >
                            {t('contact_text1')}<br /> {t('contact_text2')}
                        </motion.h4>
                    </Container>
                </div>
                <Container className={"contact-container content"}>
                    <Row>
                        <Col className={"col"} lg={9}>
                            <motion.div
                                className={"form-wrapper"}
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                            >
                                <motion.h3
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1 }}
                                >
                                    {t('contact_title2')}
                                </motion.h3>
                                <motion.p
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, delay: 0.2 }}
                                >
                                    {t('contact_text3')} <animated.div style={bounce4} className="d-inline-block">
                                        <a href="/faq">{t('contact_text4')}</a>
                                    </animated.div> {t('contact_text5')}
                                </motion.p>
                                <hr />
                                <Form>
                                    <Row className={"mb-0"}>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 fw-bold" controlId="cf.name">
                                                <Form.Label>{t('naam')}</Form.Label>
                                                <Form.Control type="text" placeholder={t('naam_placeholder')} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 fw-bold" controlId="cf.email">
                                                <Form.Label>{t('email')}</Form.Label>
                                                <Form.Control type="email" placeholder={t('email_placeholder')} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className="mb-3 fw-bold" controlId="cf.subject">
                                        <Form.Label>{t('onderwerp')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('onderwerp_placeholder')} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 fw-bold" controlId="cf.message">
                                        <Form.Label>{t('bericht')}</Form.Label>
                                        <Form.Control as="textarea" className={"cf-textarea"} rows={3} />
                                    </Form.Group>
                                    <Form.Check
                                        inline
                                        label={t('akkoord')}
                                        name="gdpr"
                                        type={"checkbox"}
                                        id={"gdpr"}
                                    />
                                    <animated.button
                                        className={"join-button submit-form mt-2"}
                                        style={bounce1}
                                    >
                                        {t('contact_button')}
                                    </animated.button>
                                </Form>
                            </motion.div>
                        </Col>
                        <Col lg={3}>
                            <motion.div
                                className={"form-wrapper"}
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                            >
                                <p className={"fw-bold"}>{t('socials')}</p>

                                <animated.div style={bounce2} className="d-inline-block">
                                <a href={"mailto:info@usell.be"}>
                                    <i className={"bi bi-envelope-at-fill text-lg-start"}></i> info@usell.be
                                </a>
                                </animated.div>
                                <br />
                                <animated.div style={bounce3} className="d-inline-block">
                                <a href={"https://x.com"}>
                                    <i className={"bi bi-twitter-x"}></i> Twitter/X
                                </a>
                                </animated.div>
                                <br />
                                <animated.div style={bounce4} className="d-inline-block">
                                <a href={"https://facebook.com"}>
                                    <i className={"bi bi-facebook"}></i> Facebook/META
                                </a>
                                </animated.div>
                                <br />
                                <animated.div style={bounce1} className="d-inline-block">
                                <a href={"https://instagram.com"}>
                                    <i className={"bi bi-instagram"}></i> Instagram/META
                                </a>
                                </animated.div>
                                <br />
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}