import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { userMetadata } = useContext(AuthContext);

    if (!userMetadata) {
        return <Navigate to="/" />;
    }

    if (userMetadata.type === "admin") {
        return <Navigate to="/admin" />;
    }

    const isProfileComplete = userMetadata?.first_name && userMetadata?.last_name && userMetadata?.birthdate && userMetadata?.type && userMetadata?.address;

    if (!isProfileComplete) {
        return <Navigate to="/vervolledig-profiel" />;
    }

    return children;
};

export default ProtectedRoute;