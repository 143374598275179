import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

export function MyStockBidsPage() {
    const [forsaleStocks, setForsaleStocks] = useState([]);
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchForsaleStocks = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/stock/forsale-stock/${user.id}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setForsaleStocks(data);
            } catch (error) {
                console.error("Error fetching forsale stock:", error);
            }
        };

        fetchForsaleStocks();
    }, [token, user]);

    const handleBidStock = async (stockItemId, action) => {
        const url = `https://usell.be/api/stock/${stockItemId}/${action}-bid/`;
        const options = {
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);

            if (response.status === 302) {
                const data = await response.json();
                if (data.onboarding_url) {
                    window.location.href = data.onboarding_url;
                    return;
                }
            }

            if (!response.ok) throw new Error(`Failed to ${action} bid`);

            setForsaleStocks((prev) => prev.filter((stockItem) => stockItem.id !== stockItemId));
        } catch (error) {
            console.error(`Error ${action} bid:`, error);
        }
    };

    const handleEarlyAcceptBid = async (stockItemId) => {
        const url = `https://usell.be/api/stock/${stockItemId}/accept-bid-early/`;
        const options = {
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);

            if (response.status === 302) {
                const data = await response.json();
                if (data.onboarding_url) {
                    window.location.href = data.onboarding_url;
                    return;
                }
            }

            if (!response.ok) throw new Error("Failed to accept bid early");

            setForsaleStocks((prev) => prev.filter((stockItem) => stockItem.id !== stockItemId));
        } catch (error) {
            console.error("Error accepting bid early:", error);
        }
    };


    return (
        <>
            <UserNavigation />
            <motion.div
                className="appointments-hero"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div className={"hero-background appointments-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <motion.h1
                        className="appointments-title"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        {t("bid")}
                    </motion.h1>
                </Container>
            </motion.div>
            <section className="bids-background">
                <Container>
                    <motion.h2
                        className="mb-4 shadow-text"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        {t("bid2")}
                    </motion.h2>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>{t('bid3')}</th>
                                <th>{t('bid4')}</th>
                                <th>{t('bid5')}</th>
                            </tr>
                        </thead>
                        <motion.tbody initial="hidden" animate="visible">
                            {forsaleStocks && forsaleStocks.map((auction) => (
                                <motion.tr
                                    key={auction.id}
                                >
                                    <td>{auction.name}</td>
                                    <td>
                                        <FontAwesomeIcon icon={faEuroSign} />
                                        {auction.highest_bid ? auction.highest_bid : t('bid6')}
                                    </td>
                                    <td>
                                        <Button variant="success" onClick={() => handleBidStock(auction.id, "accept")}>{t('bid7')}</Button>
                                        <Button variant="danger" className="ms-2" onClick={() => handleBidStock(auction.id, "decline")}>{t('bid8')}</Button>
                                    </td>
                                </motion.tr>
                            ))}
                        </motion.tbody>
                    </Table>
                </Container>
            </section>
        </>
    );
}

