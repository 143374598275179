import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const { user, userMetadata, isAuthenticated, apiAccessToken } = useContext(AuthContext);

    const fetchNotifications = useCallback(async () => {
        if (!isAuthenticated || !userMetadata || !apiAccessToken) {
            console.error('User is not authenticated, userMetadata not available, or apiAccessToken is missing');
            return;
        }

        try {
            const userId = userMetadata.id;
            const response = await fetch(`https://usell.be/api/notifications/user/${userId}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${apiAccessToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch notifications');
            }

            const data = await response.json();
            const unreadNotifications = data.filter(notification => !notification.read);
            setNotifications(unreadNotifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }, [isAuthenticated, userMetadata, apiAccessToken]);

    const markAsRead = useCallback(async (id) => {
        if (!isAuthenticated || !user || !apiAccessToken) {
            console.error('User is not authenticated or apiAccessToken is missing');
            return;
        }

        try {
            const response = await fetch(`https://usell.be/api/notifications/read/${id}/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${apiAccessToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark notification as read');
            }

            setNotifications((prevNotifications) =>
                prevNotifications.filter(notification => notification.id !== id)
            );
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    }, [isAuthenticated, user, apiAccessToken]);

    useEffect(() => {
        if (apiAccessToken) {
            fetchNotifications();
        }
    }, [fetchNotifications, apiAccessToken]);

    return (
        <NotificationContext.Provider value={{ notifications, fetchNotifications, markAsRead }}>
            {children}
        </NotificationContext.Provider>
    );
};