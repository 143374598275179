import React, { useEffect, useState, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { UserNavigation } from '../../../components/UserNavigation';
import { AuthContext } from "../../../contexts/AuthContext";

export function SuccessOnboardingPage({ token }) {
    const [notificationCreated, setNotificationCreated] = useState(false);
    const [buttonHref, setButtonHref] = useState("");
    const [buttonText, setButtonText] = useState("");
    const { userMetadata } = useContext(AuthContext);
    const user = userMetadata;

    useEffect(() => {
        const createNotification = async () => {
            if (!user?.id || !token || notificationCreated) return;
            console.log(user);

            const URL = `https://usell.be/api/notifications/onboarding`;
            const options = {
                method: "POST",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    message: "Je hebt succesvol geregistreerd en kan nu betalingen ontvangen of biedingen plaatsen. Ga naar je biedingen en probeer opnieuw een bod te accepteren of plaats een nieuw bod op een kavel.",
                    notification_type: "info",
                }),
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
                console.log("Notification created successfully");
                setNotificationCreated(true);
            } catch (error) {
                console.error("Error creating notification:", error);
            }
        };

        createNotification();
    }, [user?.id, token, notificationCreated]);

    useEffect(() => {
        console.log(user);
        if (user?.type === "seller") {
            setButtonHref("https://usell.be/dashboard/biedingen");
            setButtonText("Ga naar Mijn Biedingen");
        } else {
            setButtonHref("https://usell.be/veilingen");
            setButtonText("Ga naar Veilingen");
        }
    }, [user?.type]);

    return (
        <>
            <UserNavigation />
            <div className="content-padding-top-lg content-padding-bottom-lg">
                <Container>
                    <h1>Registratie bij Stripe is gelukt!</h1>
                    <p>Dank u om uw registratie te vervolledigen, U kan nu zonder problemen betalingen ontvangen.</p>
                    <p>Ga naar jouw biedingen pagina en probeer nu opnieuw een bod te accepteren</p>
                    <button className="onboarding-button">
                        <a className="onboarding-text" href={buttonHref}>{buttonText}</a>
                    </button>
                </Container>
            </div>
        </>
    );
}