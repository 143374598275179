import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export function PaymentDeclinedPage() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const updatePaymentStatus = async () => {
      if (!isAuthenticated) {
        loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });
        return;
      }

      const params = new URLSearchParams(location.search);
      const paymentIntent = params.get("payment_intent");

      if (paymentIntent) {
        try {
          const response = await axios.get(`/api/payment-declined/?payment_intent=${paymentIntent}`);
        } catch (error) {
          console.error("Error updating payment status:", error);
        }
      }
    };

    updatePaymentStatus();
  }, [location, isAuthenticated, loginWithRedirect]);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
      <h2 className="mb-4">Payment Declined</h2>
      <p>Unfortunately, your payment was not successful. Please try again or contact support if the issue persists.</p>
      <Button variant="danger" onClick={() => navigate("/dashboard")}>Back to Dashboard</Button>
    </Container>
  );
}