import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { StockList } from "../components/StockList";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function MyStockPage(props) {
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const [stockItems, setStockItems] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (!user?.id || !token) return;
    
        const URL = `https://usell.be/api/user/stock/${user.id}/`;
        const option = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };
    
        fetchData();
    
        async function fetchData() {
            try {
                const response = await fetch(URL, option);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                console.log(data);
                const tekoopStock = data.filter(stockItem => stockItem.status === "TEKOOP");
                setStockItems(tekoopStock);
            } catch (error) {
                console.error(error);
            }
        }
    }, [user.id, token]);
    


    return (
        <>
            <UserNavigation />
            <div className={"winning-hero"}>
                <div className={"hero-background winning-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1 className="winning-titel responsive-title">{t('stock_title')}</h1>
                    <h4>{t('stock_text')}</h4>
                </Container>
            </div>

            <Container className={"content winnende"}>
                <Row>
                    <StockList stockItems={stockItems} following={true} />
                </Row>
            </Container>
        </>
    );

}