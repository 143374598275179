import React, { useContext } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function UserDashboardPage() {
    const { userMetadata } = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <>
            <UserNavigation />
            <div className="create-auction-hero">
                <div className="hero-background create-auction-background"></div>
                <Container className="content content-padding-top-lg content-padding-bottom-lg dashboard-top">
                    <h1 className="text-center text-white responsive-title">{t('dashboard_title')}</h1>
                </Container>
            </div>
            <Container fluid className="mt-4">
                <DashboardContent user={userMetadata} />
            </Container>
        </>
    );
}

function DashboardContent({ user }) {

    const { t } = useTranslation();

    if (user.type === "seller" || user.type === "company") {
        return (
            <Row className="admin-hover-card mb-5 pb-5 dashboard-space">
                <Row className="px-0 mx-0 dashboard-space">
                    <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/mijn-veilingen?filter=active">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard1')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-bar-chart-fill float-end p-3 bg-primary-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard2')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/maak-veiling">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard3')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-clock-history float-end p-3 bg-success-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-md-block d-lg-block">
                                        {t('dashboard4')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row className="px-0 mx-0 gy-1 dashboard-space">
                    <Col className="gap-0 col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/afspraken">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard5')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-calendar-check float-end p-3 bg-warning-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard6')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col className="gap-0 col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/profiel">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col className="col-10">
                                                <h5 className="text-wrap">{t('dashboard7')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-person float-end p-3 bg-info-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard8')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Row>
        );
    } else if (user.type === "buyer") {
        return (
            <Row className="admin-hover-card mb-5 pb-5 dashboard-space">
                <Row className="px-0 mx-0 dashboard-space">
                    <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/veilingen">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard9')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-hourglass-split float-end p-3 bg-primary-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard10')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/gewonnen">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard11')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-trophy float-end p-3 bg-success-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-md-block d-lg-block">
                                        {t('dashboard12')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row className="px-0 mx-0 gy-1 dashboard-space">
                    <Col className=" col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/afspraken">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <h5>{t('dashboard13')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-calendar-check float-end p-3 bg-warning-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard14')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col className=" col-12 col-md-6 col-lg-6 dashboard-col">
                        <Link as={Link} to="/dashboard/profiel">
                            <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col className="col-10">
                                                <h5 className="text-wrap">{t('dashboard15')}</h5>
                                            </Col>
                                            <Col>
                                                <i className="bi bi-person float-end p-3 bg-info-subtle rounded"></i>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text className="d-none d-lg-block d-md-block">
                                        {t('dashboard16')}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Row>
        );
    }
    return null;
}