import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AuctionDetailModal = ({ show, handleClose, auction }) => {
    if (!auction) return null;

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Veiling Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Naam:</h4>
                <p>{auction.name}</p>

                <h4>Beschrijving:</h4>
                <p>{auction.description}</p>

                <h4>Startdatum:</h4>
                <p>{new Date(auction.start_date).toLocaleString()}</p>

                <h4>Einddatum:</h4>
                <p>{new Date(auction.end_date).toLocaleString()}</p>

                <h4>Status:</h4>
                <p>{auction.status}</p>

                <h4>Financiering:</h4>
                <p>{auction.financing ? 'Ja' : 'Nee'}</p>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Sluit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AuctionDetailModal;