import React from "react";
import { Row, Col, Form } from "react-bootstrap";

export function ProfileInfoForm({ formData, handleChange }) {
    return (
        <>
            <h2>Account informatie</h2>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Voornaam</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="first_name" 
                            value={formData.first_name} 
                            onChange={handleChange} 
                            placeholder="Voornaam" 
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Achternaam</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="last_name" 
                            value={formData.last_name} 
                            onChange={handleChange} 
                            placeholder="Achternaam" 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Straat</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="street" 
                            value={formData.street} 
                            onChange={handleChange} 
                            placeholder="Straat" 
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Huisnummer</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="house_number" 
                            value={formData.house_number} 
                            onChange={handleChange} 
                            placeholder="Huisnummer" 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="zip_code" 
                            value={formData.zip_code} 
                            onChange={handleChange} 
                            placeholder="Postcode" 
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Stad</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="city" 
                            value={formData.city} 
                            onChange={handleChange} 
                            placeholder="Stad" 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Provincie</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="province" 
                            value={formData.province} 
                            onChange={handleChange} 
                            placeholder="Provincie"
                            required 
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Land</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="country" 
                            value={formData.country} 
                            onChange={handleChange} 
                            placeholder="Land" 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Geboortedatum</Form.Label>
                        <Form.Control 
                            type="date" 
                            name="birthdate" 
                            value={formData.birthdate} 
                            onChange={handleChange} 
                            placeholder="Geboortedatum" 
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Telefoonnummer</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="phone_number" 
                            value={formData.phone_number} 
                            onChange={handleChange} 
                            placeholder="Telefoonnummer" 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}