import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { UserNavigation } from "../../../components/UserNavigation";

export function PaymentPage() {
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const navigate = useNavigate();
  const token = apiAccessToken;
  const user = userMetadata;

  useEffect(() => {
    const fetchPayments = async (auctionId) => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/payment/payments/${auctionId}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
  
      try {
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log(data);
        setPayments(data);
      } catch (error) {
        console.error("Failed to fetch payments:", error);
      }
    };
  
    if (filterStatus) {
      URL += `?status=${filterStatus}`;
    }
  
    if (user) {
      fetchPayments();
    }
  
  }, [ filterStatus, user, token])

  const handlePayment = async (paymentId) => {
    try {
      const response = await fetch(`https://usell.be/payment/create-setup-intent/${paymentId}/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment_id: paymentId }),
      });

      if (!response.ok) throw new Error("Failed to create setup intent");

      const data = await response.json();
      navigate(`/betaling/${paymentId}/afrekenen`, { state: { clientSecret: data.client_secret } });
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  return (
    <>
      <UserNavigation />
      <div className={"appointments-hero"}>
        <div className={"hero-background appointments-background"}></div>
        <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
          <h1 className={"appointments-title"}>Betalingen</h1>
        </Container>
      </div>
      <Container className="mt-5">
        <Form.Group className="mb-3">
          <Form.Label>Filter op status</Form.Label>
          <Form.Control as="select" value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
            <option value="">Alle</option>
            <option value="PENDING">Niet voltooide betalingen (Pending)</option>
            <option value="ON_HOLD">Vastgehouden betalingen (On Hold)</option>
            <option value="COMPLETED">Succesvolle betalingen (Completed)</option>
          </Form.Control>
        </Form.Group>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Veiling naam</th>
              <th>Hoeveelheid</th>
              <th>Status</th>
              <th>Handelingen</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.auction.name}</td>
                <td>€{payment.amount}</td>
                <td>{payment.status}</td>
                <td>
                  {payment.status === "PENDING" && (
                    <Button variant="success" onClick={() => handlePayment(payment.id)}>Vervolledig betaalgegevens</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}