import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import React, { useContext } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { Notification } from './Notification';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from "react-flag-kit";

export function Navigation(props) {

    const { currentPage } = props;
    const { user, logout, loginWithRedirect } = useContext(AuthContext);
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <Navbar expand="lg" bg="light" variant="light" sticky="top">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src="/images/usell-logo.png"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} to="/" className={`nav-link ${currentPage === 'home' ? 'active' : ''}`}>
                            {t('nav1')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/over-ons" className={`nav-link ${currentPage === 'over-ons' ? 'active' : ''}`}>
                            {t('nav2')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contacteer-ons" className={`nav-link ${currentPage === 'contact' ? 'active' : ''}`}>
                            {t('nav3')}
                        </Nav.Link>

                        {user ? (
                            <Nav>
                                <NavDropdown className="nav-drop" align="end" title={user.name}>
                                    <Nav.Link as={Link} to="/dashboard">{t('nav5')}</Nav.Link>
                                    <Nav.Link onClick={() => logout({ returnTo: window.location.origin })}>
                                        {t('nav6')}
                                    </Nav.Link>
                                </NavDropdown>
                                <Notification />
                            </Nav>
                        ) : (
                            <Nav className="ms-auto">
                                <Nav.Link onClick={loginWithRedirect}>{t('nav4')}</Nav.Link>
                            </Nav>
                        )}

                        <Nav className="language-selector">
                            <FlagIcon code="BE" size={24} onClick={() => changeLanguage('nl')} style={{ cursor: 'pointer', marginRight: '10px' }} className="flag-icon" />
                            <FlagIcon code="GB" size={24} onClick={() => changeLanguage('en')} style={{ cursor: 'pointer', marginRight: '10px' }} className="flag-icon" />
                            <FlagIcon code="FR" size={24} onClick={() => changeLanguage('fr')} style={{ cursor: 'pointer', marginRight: '10px' }} className="flag-icon" />
                        </Nav>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}