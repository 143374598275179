import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const CarDetailModal = ({ show, handleClose, car }) => {
    if (!car) return null;

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Auto Specificaties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <h4>Merk:</h4>
                        <p>{car.brand_name}</p>

                        <h4>Model:</h4>
                        <p>{car.model}</p>

                        <h4>Bouwjaar:</h4>
                        <p>{car.build_year}</p>

                        <h4>Kilometers:</h4>
                        <p>{car.kilometers} km</p>

                        <h4>Brandstof:</h4>
                        <p>{car.fuel_type}</p>

                        <h4>Transmissie:</h4>
                        <p>{car.transmission}</p>
                    </Col>
                    <Col md={6}>
                        <h4>Aandrijving:</h4>
                        <p>{car.drive}</p>

                        <h4>Aantal deuren:</h4>
                        <p>{car.number_of_doors}</p>

                        <h4>Kleur:</h4>
                        <p>{car.color}</p>

                        <h4>Chassisnummer:</h4>
                        <p>{car.chassis_number}</p>

                        <h4>Car BTW:</h4>
                        <p>{car.car_btw ? car.car_btw : 'Geen Car BTW beschikbaar'}</p>

                        <h4>Documenten:</h4>
                        <ul>
                            <li>
                                Gelijkvormigheidsattest: {car.boorddocument_url ?
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> :
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />}
                            </li>
                            <li>
                                Inschrijvingsbewijs: {car.kenteken_url ?
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> :
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />}
                            </li>
                            <li>
                                Keuringsattest: {car.kenteken2_url ?
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> :
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />}
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CarDetailModal;