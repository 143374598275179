import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import { useContext, useEffect, useState } from "react";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function WonAuctionsPage(props) {
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const token = apiAccessToken;
    const user = userMetadata;
    const [auctions, setAuctions] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!user?.id || !token) return;

        const URL = `https://usell.be/api/winning-auctions/${user.id}/`;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                const completedAuctions = data.filter(
                    (item) =>
                        item.winning_bid &&
                        ["COMPLETED", "FINISHED", "PAID"].includes(item.auction.status)
                );
                setAuctions(completedAuctions.map((item) => item.auction));
            } catch (error) {
                console.error(error);
            }
        }
    }, [user.id, token]);

    if (!auctions) return "Loading";

    return (
        <>
            <UserNavigation />
            <motion.div
                className="winning-hero"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div className="hero-background won-background"></div>
                <Container className="content content-padding-top-lg content-padding-bottom-lg">
                    <motion.h1
                        className="winning-title"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.8 }}
                    >
                        {t("won_title")}
                    </motion.h1>
                    <motion.h4
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 0.8 }}
                    >
                        {t("won_text")}
                    </motion.h4>
                </Container>
            </motion.div>
            <Container className="content">
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden: { opacity: 0, y: 10 },
                        visible: {
                            opacity: 1,
                            y: 0,
                            transition: {
                                delayChildren: 0.3,
                                staggerChildren: 0.2,
                            },
                        },
                    }}
                >
                    <Row>
                        <CarList auctions={auctions} following={true} />
                    </Row>
                </motion.div>
            </Container>
        </>
    );
}