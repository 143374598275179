import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {CarList} from "../components/CarList";
import {useEffect, useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export function NewAuctionsPage(props) {

    const [auctions, setAuctions] = useState(null);
    const { apiAccessToken } = useContext(AuthContext);
    useEffect(() => {
        const URL = "https://usell.be/api/new-auctions/";
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, [apiAccessToken]);

    return (
        <>
            <div className={"auctions-hero"}>
                <div className={"hero-background auctions-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1>AFLOPENDE VEILINGEN</h1>
                    <h4>Hier vindt u de veilingen die hun einde naderen.</h4>
                </Container>
            </div>

            <Container className={"content"}>
                <Row>
                    <CarList auctions={auctions}/>
                </Row>
            </Container>
        </>
    );
}