import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

export function MyBidsPage() {
    const [pendingAuctions, setPendingAuctions] = useState([]);
    const [ongoingAuctions, setOngoingAuctions] = useState([]);
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPendingAuctions = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/auction/pending-auctions/${user.id}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                console.log(data);
                setPendingAuctions(data);
            } catch (error) {
                console.error("Error fetching pending auctions:", error);
            }
        };

        fetchPendingAuctions();
    }, [token, user]);

    const handleBidAction = async (auctionId, action) => {
        const url = `https://usell.be/api/auction/${auctionId}/${action}-bid/`;
        const options = {
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);

            if (response.status === 302) {
                const data = await response.json();
                if (data.onboarding_url) {
                    window.location.href = data.onboarding_url;
                    return;
                }
            }

            if (!response.ok) throw new Error(`Failed to ${action} bid`);

            setPendingAuctions((prev) => prev.filter((auction) => auction.id !== auctionId));
        } catch (error) {
            console.error(`Error ${action} bid:`, error);
        }
    };

    useEffect(() => {
        const fetchCurrentAuctions = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/auction/current-auctions/${user.id}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setOngoingAuctions(data);
            } catch (error) {
                console.error("Error fetching ongoing auctions:", error);
            }
        };

        fetchCurrentAuctions();
    }, [token, user]);

    const handleEarlyAcceptBid = async (auctionId) => {
        const url = `https://usell.be/api/auction/${auctionId}/accept-bid-early/`;
        const options = {
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);

            if (response.status === 302) {
                const data = await response.json();
                if (data.onboarding_url) {
                    window.location.href = data.onboarding_url;
                    return;
                }
            }

            if (!response.ok) throw new Error("Failed to accept bid early");

            setOngoingAuctions((prev) => prev.filter((auction) => auction.id !== auctionId));
        } catch (error) {
            console.error("Error accepting bid early:", error);
        }
    };

    const tableRowVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { delay: index * 0.2, duration: 0.6 },
        }),
    };

    console.log(pendingAuctions);

    return (
        <>
            <UserNavigation />
            <motion.div
                className="appointments-hero"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div className="hero-background appointments-background"></div>
                <Container className="content content-padding-top-lg content-padding-bottom-lg">
                    <motion.h1
                        className="appointments-title"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        {t("bid")}
                    </motion.h1>
                </Container>
            </motion.div>

            <section className="bids-background">
                <Container>
                    <motion.h2
                        className="mb-4 shadow-text"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        {t("bid2")}
                    </motion.h2>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>{t("bid3")}</th>
                                <th>{t("bid4")}</th>
                                <th>{t("bid5")}</th>
                            </tr>
                        </thead>
                        <motion.tbody initial="hidden" animate="visible">
                            {pendingAuctions.map((auction, index) => {
                                const highestBid = auction.bids.sort((a, b) => b.amount - a.amount)[0];
                                return (
                                    <motion.tr
                                        key={auction.id}
                                        custom={index}
                                        variants={tableRowVariants}
                                    >
                                        <td>{auction.name}</td>
                                        <td>
                                            <FontAwesomeIcon icon={faEuroSign} />
                                            {highestBid ? highestBid.amount : t("bid6")}
                                        </td>
                                        <td>
                                            <Button
                                                variant="success"
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={() => handleBidAction(auction.id, "accept")}
                                            >
                                                {t("bid7")}
                                            </Button>
                                            <Button
                                                variant="danger"
                                                className="ms-2"
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={() => handleBidAction(auction.id, "decline")}
                                            >
                                                {t("bid8")}
                                            </Button>
                                        </td>
                                    </motion.tr>
                                );
                            })}
                        </motion.tbody>
                    </Table>
                </Container>
            </section>

            <section className="bids-background">
                <Container>
                    <motion.h2
                        className="mb-4 shadow-text"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        {t("bid9")}
                    </motion.h2>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Veilingnaam</th>
                                <th>Hoogste Bod</th>
                                <th>Handelingen</th>
                            </tr>
                        </thead>
                        <motion.tbody initial="hidden" animate="visible">
                            {ongoingAuctions.map((auction, index) => {
                                const highestBid = auction.bids.sort((a, b) => b.amount - a.amount)[0];
                                return (
                                    <motion.tr
                                        key={auction.id}
                                        custom={index}
                                        variants={tableRowVariants}
                                    >
                                        <td>{auction.name}</td>
                                        <td>
                                            <FontAwesomeIcon icon={faEuroSign} />
                                            {highestBid ? highestBid.amount : "Geen biedingen"}
                                        </td>
                                        <td>
                                            <Button
                                                variant="success"
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={() => handleEarlyAcceptBid(auction.id)}
                                            >
                                                Vroegtijdig Accepteren
                                            </Button>
                                        </td>
                                    </motion.tr>
                                );
                            })}
                        </motion.tbody>
                    </Table>
                </Container>
            </section>
        </>
    );
}