import React from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


export function PaymentSuccessPage() {
  const navigate = useNavigate();

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
      <h2 className="mb-4">Payment Successful</h2>
      <p>Thank you! Your payment has been successfully processed. You can now continue using our services.</p>
      <Button variant="success" onClick={() => navigate("/dashboard")}>Back to Dashboard</Button>
    </Container>
  );
}