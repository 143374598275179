import {Link, useLocation} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import React from "react";

export function AdminNavbar() {
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className={"pb-4"}>
            <Nav className={"bg-dark justify-content-center"} variant="pills" defaultActiveKey="/admin">
                <Nav.Item>
                    <Nav.Link className={"bg-dark text-light"} as={Link} to="/admin" active={isActive("/admin")}>
                        Dashboard
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={"bg-dark text-light"} as={Link} to="/admin/gebruikers"
                              active={isActive("/admin/gebruikers")}>
                        Gebruikers
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={"bg-dark text-light"} as={Link} to="/admin/veilingen"
                              active={isActive("/admin/veilingen")}>
                        Veilingen
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={"bg-dark text-light"} as={Link} to="/admin/afgekeurde-wagens"
                              active={isActive("/admin/afgekeurde-wagens")}>
                        Afgekeurde wagens
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
}