import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Button, Modal, Form } from "react-bootstrap";
import AvatarEditor from 'react-avatar-editor';
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { ProfileInfoForm } from "../../components/ProfileInfoForm";
import { PasswordForm } from "../../components/PasswordForm";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography } from 'mdb-react-ui-kit';
import './ProfilePage.css';

export function ProfilePage(props) {
    const [profile, setProfile] = useState(null);
    const [originalProfile, setOriginalProfile] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [confirmText, setConfirmText] = useState("");
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const { userMetadata, setUserMetadata, apiAccessToken, logout } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [image, setImage] = useState(null);
    const [editor, setEditor] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchProfile = async () => {
            if (!user?.id || !token) return;
            const profileUrl = `https://usell.be/api/user/${user.Authsub}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(profileUrl, options);
                if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
                const data = await response.json();
                setProfile(data);
                setOriginalProfile(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProfile();
    }, [user.Authsub, user, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const snakeCaseName = name.replace(/([A-Z])/g, "_$1").toLowerCase();
        setProfile({ ...profile, [snakeCaseName]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const address = {
            street: profile.street,
            house_number: profile.house_number,
            zip_code: profile.zip_code,
            city: profile.city,
            province: profile.province,
            country: profile.country,
            phone_number: profile.phone_number
        };

        try {
            const newAddressResponse = await fetch(`https://usell.be/api/address/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(address),
            });

            if (!newAddressResponse.ok) {
                const errorText = await newAddressResponse.text();
                throw new Error(errorText);
            }

            const newAddress = await newAddressResponse.json();

            const updatedUser = {
                id: profile.id,
                Authsub: profile.Authsub,
                first_name: profile.first_name,
                last_name: profile.last_name,
                email: profile.email,
                birthdate: profile.birthdate,
                address_id: newAddress.id,
                type: profile.type,
                status: profile.status,
                website: profile.website,
            };

            const userResponse = await fetch(`https://usell.be/api/update-user/${profile.id}/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedUser),
            });

            if (!userResponse.ok) {
                const errorText = await userResponse.text();
                throw new Error(errorText);
            }

            const updatedProfile = await userResponse.json();
            setProfile(updatedProfile);
            setOriginalProfile(updatedProfile);
            setShowProfileModal(false);
            setUserMetadata(updatedProfile);
        } catch (error) {
            console.error("Error in handleSubmit:", error);
            setError(error.message);
        }
    };

    const handleCloseProfileModal = () => {
        setProfile(originalProfile);
        setShowProfileModal(false);
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setShowCropModal(true);
        }
    };

    const handleSaveCroppedImage = () => {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            canvas.toBlob(async (blob) => {
                const formData = new FormData();
                formData.append("profile_picture", blob, "profile_picture.png");

                try {
                    const response = await fetch(`https://usell.be/api/user/${profile.id}/profile-picture/update/`, {
                        method: "PATCH",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: formData,
                    });

                    if (!response.ok) throw new Error("Failed to upload profile picture");

                    const updatedProfile = await response.json();
                    setProfile(updatedProfile);
                    setShowCropModal(false);
                    setUserMetadata(updatedProfile);
                    window.location.reload();
                } catch (error) {
                    console.error(error);
                }
            });
        }
    };

    const handleDeleteProfilePicture = async () => {
        try {
            const response = await fetch(`https://usell.be/api/user/${profile.id}/profile-picture/delete/`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error("Failed to delete profile picture");

            const updatedProfile = { ...profile, profile_picture: null };
            setProfile(updatedProfile);
            setUserMetadata(updatedProfile);
        } catch (error) {
            console.error(error);
        }
    };

    const setEditorRef = (editor) => setEditor(editor);

    const handleProfilePictureClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteAccount = async () => {
        if (confirmText !== "DELETE") {
            setError("You must type DELETE to confirm.");
            return;
        }

        try {
            const response = await fetch(`https://usell.be/api/user/${profile.id}/account/delete/`, {
                method: "DELETE",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            });

            if (response.ok) {
                logout({ returnTo: window.location.origin });
            } else {
                const errorData = await response.json();
                setError(errorData.message || "An error occurred");
            }
        } catch (error) {
            setError("Failed to delete account.");
        }
    };

    const handlePasswordChange = async () => {
        setError(null);

        try {
            const response = await fetch(`https://usell.be/api/user/${user.id}/change-password/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                alert("Password reset email sent. Please check your email.");
                setShowPasswordModal(false);
            } else {
                const errorData = await response.json();
                setError(errorData.message || "An error occurred while sending the password reset email.");
            }
        } catch (error) {
            console.error("Password reset error:", error);
            setError("Failed to send password reset email.");
        }
    };

    if (!profile) return "Loading";

    const address = profile.address;

    return (
        <>
            <UserNavigation />
            <motion.div
                className="profile-hero"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div className="hero-background profile-background"></div>
                <Container className="content content-padding-top-lg content-padding-bottom-lg">
                    <motion.h1
                        className="responsive-title"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.8 }}
                    >
                        {t("profile1")}
                    </motion.h1>
                </Container>
            </motion.div>

            <motion.section
                className="profile-section"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.8 }}
            >
                <MDBContainer className="py-5 h-100">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="8" className="mb-4 mb-lg-0">
                            <MDBCard className="profile-card">
                                <MDBRow className="g-0">
                                    <MDBCol md="4" className="profile-card-left">
                                        <MDBCardImage
                                            src={profile.profile_picture || '/images/placeholder.jpg'}
                                            alt="Avatar"
                                            className="profile-avatar"
                                            fluid
                                            onClick={handleProfilePictureClick}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleProfilePictureChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                        <MDBTypography tag="h5" className="profile-name">{profile.first_name} {profile.last_name}</MDBTypography>
                                        <Button variant="danger" onClick={handleDeleteProfilePicture}>
                                            {t('profile')}
                                        </Button>
                                    </MDBCol>
                                    <MDBCol md="8">
                                        <MDBCardBody className="p-4">
                                            <MDBTypography tag="h6">{t('profile2')}</MDBTypography>
                                            <hr className="divider" />
                                            <MDBRow className="pt-1">
                                                <MDBCol size="6" className="mb-3">
                                                    <MDBTypography tag="h6">{t('profile3')}</MDBTypography>
                                                    <MDBCardText className="text-muted">{profile.first_name}</MDBCardText>
                                                </MDBCol>
                                                <MDBCol size="6" className="mb-3">
                                                    <MDBTypography tag="h6">{t('profile4')}</MDBTypography>
                                                    <MDBCardText className="text-muted">{profile.last_name}</MDBCardText>
                                                </MDBCol>
                                                <MDBCol size="6" className="mb-3">
                                                    <MDBTypography tag="h6">{t('profile5')}</MDBTypography>
                                                    <MDBCardText className="text-muted">{profile.email}</MDBCardText>
                                                </MDBCol>
                                                <MDBCol size="6" className="mb-3">
                                                    <MDBTypography tag="h6">{t('profile6')}</MDBTypography>
                                                    <MDBCardText className="text-muted">{profile.birthdate}</MDBCardText>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBTypography tag="h6">{t('profile7')}</MDBTypography>
                                            <hr className="divider" />
                                            <MDBRow className="pt-1">
                                                {address && (
                                                    <>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile8')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.street}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile9')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.house_number}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile10')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.zip_code}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile11')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.city}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile12')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.province}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile13')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.country}</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol size="6" className="mb-3">
                                                            <MDBTypography tag="h6">{t('profile14')}</MDBTypography>
                                                            <MDBCardText className="text-muted">{address.phone_number}</MDBCardText>
                                                        </MDBCol>
                                                    </>
                                                )}
                                            </MDBRow>
                                            {error && <div className="text-danger">{error}</div>}
                                            <MDBRow className="pt-1 mt-4">
                                                <MDBCol size="6" className="d-flex justify-content-center">
                                                    <Button variant="primary" onClick={() => setShowProfileModal(true)}>
                                                        <AiOutlineUser /> {t('profile15')}
                                                    </Button>
                                                </MDBCol>
                                                <MDBCol size="6" className="d-flex justify-content-center">
                                                    <Button variant="warning" onClick={handlePasswordChange}>
                                                        <AiOutlineLock /> {t('profile16')}
                                                    </Button>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCardBody>
                                    </MDBCol>
                                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
                                        Verwijder Account
                                    </Button>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </motion.section>
            <Modal show={showProfileModal} onHide={handleCloseProfileModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('profile17')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProfileInfoForm formData={profile} handleChange={handleChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseProfileModal}>
                        {t('profile18')}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t('profile19')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('profile20')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PasswordForm handlePasswordChange={handlePasswordChange} />
                </Modal.Body>
            </Modal>
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('profile23')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AvatarEditor
                        ref={setEditorRef}
                        image={image}
                        width={250}
                        height={250}
                        border={50}
                        borderRadius={125}
                        color={[255, 255, 255, 0.6]}
                        scale={1.2}
                        rotate={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        {t('profile24')}
                    </Button>
                    <Button variant="primary" onClick={handleSaveCroppedImage}>
                        {t('profile25')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Account Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To delete your account, type "DELETE" in the box below and click confirm.</p>
                    <Form.Control
                        type="text"
                        placeholder="Type DELETE"
                        value={confirmText}
                        onChange={(e) => setConfirmText(e.target.value)}
                    />
                    {error && <p className="text-danger">{error}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteAccount}>
                        Confirm Deletion
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}