import React, { useEffect, useState } from "react";

const CookiePopup = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!document.cookie.includes("cookieBy=codinglab")) {
            setShowPopup(true);
        }
    }, []);

    const acceptCookies = () => {
        document.cookie = "cookieBy=codinglab; max-age=" + 60 * 60 * 24 * 30;
        setShowPopup(false);
    };

    const declineCookies = () => {
        setShowPopup(false);
    };

    return (
        showPopup && (
            <div className="wrapper show">
                <header>
                    <i className="fa fa-cookie-bite"></i>
                    <h2 className="h3 mt-3">Cookies accepteren</h2>
                </header>
                <div className="data">
                    <p>
                        We gebruiken cookies om inhoud te personaliseren, sociale
                        mediafuncties aan te bieden en ons verkeer te analyseren.{" "}
                        <a href="https://cdn.cookiecode.nl/cookie/usell.be/nl/pdf">Meer lezen</a>
                    </p>
                </div>
                <div className="buttons">
                    <button className="button" id="acceptBtn" onClick={acceptCookies}>
                        Akkoord
                    </button>
                    <button className="button" id="declineBtn" onClick={declineCookies}>
                        Weigeren
                    </button>
                </div>
            </div>
        )
    );
};

export default CookiePopup;